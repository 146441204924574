import React from "react";
import MainPage from "./components/MainPage.jsx";

function App() {
  return (
    <>
      <MainPage></MainPage>
    </>
  );
}

export default App;
